import Head from 'next/head';
import { checkWindow, getHome } from '@vfit/shared/data-access';
import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { IHeadSeo } from './headSeo.models';

const DEFAULT_SEO_IMAGE_URL =
  'https://www.vodafone.it/portal/resources/media/Other/vfa/media/images/icone/ico_header_logo_red_04_12_19.png';

const HeadSeo = ({
  title,
  ogImageUrl,
  ogDescription,
  canonicalUrl,
  metaRobots,
  children,
}: IHeadSeo) => {
  const router = useRouter();
  const getCanonicalDefault = useCallback(() => {
    if (!checkWindow()) return '';
    return `${getHome()}${router.asPath === '/' ? '' : router.asPath}`.split('?')[0];
  }, []);

  const customMeta = metaRobots === 'true' ? <meta name="robots" content="noindex,nofollow" /> : '';

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>{title}</title>
      <meta name="description" content={ogDescription} key="description" />
      <link rel="canonical" href={canonicalUrl || getCanonicalDefault()} key="canonical" />
      <meta property="og:url" content={canonicalUrl} key="og:url" />
      <meta property="og:title" content={title} key="og:title" />
      <meta property="og:image" content={ogImageUrl || DEFAULT_SEO_IMAGE_URL} key="og:image" />
      <meta property="og:description" content={ogDescription} key="og:description" />
      {customMeta}
      {children}
    </Head>
  );
};

export default HeadSeo;
