import { ButtonSlideCustom, CustomText, ImageAtoms } from '@vfit/shared/atoms';
import { fonts } from '@vfit/shared/themes';
import { useDeviceType } from '@vfit/shared/hooks';
import { useEffect } from 'react';
import { tracking } from '@vfit/shared/data-access';
import {
  ModalTitle,
  ModalDescription,
  ContentLeft,
  ContentRight,
  ImageContainer,
  StickyWhatsappModalContainer,
  ModalSupport,
  IconWrapper,
} from './stickyWhatsappModal.style';
import { IStickyWhatsappModal } from './stickyWhatsappModal.models';

const StickyWhatsappModal = ({
  modalTitle,
  action,
  qrCodeDescription,
  qrCodeImage,
  supportIcon,
  supportDescription,
}: IStickyWhatsappModal) => {
  const { isMobile } = useDeviceType();

  useEffect(() => {
    tracking(
      {
        event_name: ['popup_open'],
        event_category: ['users'],
        event_action: 'view',
        event_label: window?.utag_data?.event_label,
        link_name: `${window?.utag_data?.page_name}:Popup:whatsapp`,
      },
      'link'
    );
  }, []);

  const onHandleClick = () => {
    tracking(
      {
        event_name: ['ui_interaction'],
        event_category: ['users'],
        event_action: 'click',
        event_label: window?.utag_data?.event_label,
        link_name: `${window?.utag_data?.page_name}:Popup:whatsapp:scrivici`,
      },
      'link'
    );

    if (action?.url) {
      window.open(action?.url, action?.isBlank === 'true' ? '_blank' : '_self');
    }
  };

  return (
    <StickyWhatsappModalContainer>
      <ContentLeft>
        <ModalTitle>
          <CustomText fontFamily={fonts.exbold} textAlign="left" text={modalTitle || ''} modal />
        </ModalTitle>
        <ButtonSlideCustom
          label={action?.title}
          onClick={() => onHandleClick()}
          labelColor="#fff"
          buttonColor="#009900"
          hoverColor="#008800"
          clickColor="#007700"
          borderColor="#009900"
          name="action_stickyWhatsappModal"
        />
        <ModalSupport>
          {!isMobile && (
            <IconWrapper>
              <ImageAtoms nameCard="supportIcon" image={supportIcon} alt="icona operatore" />
            </IconWrapper>
          )}
          <CustomText
            margin={[0, 0, 0, 8]}
            fontFamily={fonts.regular}
            size={18}
            lineHeight={20}
            lineHeightMobile={24}
            text={supportDescription || ''}
            textAlign="left"
            modal
          />
        </ModalSupport>
      </ContentLeft>
      {!isMobile && (
        <ContentRight>
          <ModalDescription>
            <CustomText
              fontFamily={fonts.regular}
              textAlign="center"
              size={18}
              text={qrCodeDescription || ''}
              modal
            />
          </ModalDescription>
          <ImageContainer>
            <ImageAtoms nameCard="whatsappQRCode" alt="QRCode Whatsapp" image={qrCodeImage} />
          </ImageContainer>
        </ContentRight>
      )}
    </StickyWhatsappModalContainer>
  );
};

export default StickyWhatsappModal;

