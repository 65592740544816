import { useState } from 'react';
import { ImageAtoms } from '@vfit/shared/atoms';
import { IStickyWhatsapp } from '@vfit/consumer/data-access';
import { tracking } from '@vfit/shared/data-access';
import VfModal from '../VfModal/vfModal';
import { StickyIcon, StickyTitle, StickyWhatsappComponent } from './stickyWhatsapp.style';
import StickyWhatsappModal from './components/stickyWhatsappModal';

const StickyWhatsapp = ({
  stickyIcon,
  stickyTitle,
  title,
  customAction,
  description,
  image,
  footerDescription,
  footerIcon,
}: IStickyWhatsapp) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const onHandleMouseEnter = () => {
    tracking(
      {
        event_name: ['ui_interaction'],
        event_category: ['users'],
        event_action: 'mouseover',
        event_label: window?.utag_data?.event_label,
        link_name: `${window?.utag_data?.page_name}:SideButton:whatsapp:mouseover`,
      },
      'link'
    );
  };

  const onHandleClick = () => {
    setShowModal(true);
    tracking(
      {
        event_name: ['ui_interaction'],
        event_category: ['users'],
        event_action: 'click',
        event_label: window?.utag_data?.event_label,
        link_name: `${window?.utag_data?.page_name}:SideButton:whatsapp:click`,
      },
      'link'
    );
  };

  return (
    <>
      <StickyWhatsappComponent
        data-name="widget_stickyWhatsapp"
        onClick={onHandleClick}
        onMouseEnter={onHandleMouseEnter}
      >
        <StickyIcon>
          <ImageAtoms nameCard="whatsappIcon" image={stickyIcon} />
        </StickyIcon>
        <StickyTitle>{stickyTitle}</StickyTitle>
      </StickyWhatsappComponent>
      <VfModal height={400} isOpen={showModal} handleClose={() => setShowModal(false)}>
        <StickyWhatsappModal
          modalTitle={title}
          action={customAction}
          qrCodeDescription={description}
          qrCodeImage={image}
          supportIcon={footerIcon}
          supportDescription={footerDescription}
        />
      </VfModal>
    </>
  );
};

export default StickyWhatsapp;

