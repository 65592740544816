import React from 'react';
import { Arrow } from '@vfit/shared-icons';
import { IButtonSlideCustomProps } from './buttonSlideCustom.models';
import { ButtonContainer, ButtonSmall, ButtonDefault, ArrowButton } from './buttonSlideCustom.style';

const ButtonSlideCustom = ({
  name,
  label,
  small,
  animated,
  rotation,
  buttonColor,
  hoverColor,
  hoverTextColor,
  clickColor,
  clickTextColor,
  labelColor,
  iconColor,
  borderColor,
  smallSize,
  arrowWidth,
  arrowHeigth,
  disabled,
  disabledButtonColor,
  disabledBorderColor,
  disabledTextColor,
  onClick,
  style
}: IButtonSlideCustomProps) => {
  const smallButton = (): React.ReactNode => (
    <ButtonSmall
      className={ style ? `ButtonDefault-style-${style}` : '' }
      aria-label={label || ''}
      size={smallSize}
      buttonColor={buttonColor}
      onClick={onClick}
      hoverColor={hoverColor}
      hoverTextColor={hoverTextColor}
      clickTextColor={clickTextColor}
      clickColor={clickColor}
      data-name={name}
    >
      <ArrowButton
        rotation={rotation || 0}
        color={iconColor || '#262626'}
        arrowWidth={arrowWidth}
        arrowHeigth={arrowHeigth}
      >
        <Arrow />
      </ArrowButton>
    </ButtonSmall>
  );
  const defaultButton = (): React.ReactNode => (
    <ButtonDefault
      className={ style ? `ButtonDefault-style-${style}` : '' }
      aria-label={label || ''}
      buttonColor={buttonColor}
      hoverColor={hoverColor}
      hoverTextColor={hoverTextColor}
      clickColor={clickColor}
      clickTextColor={clickTextColor}
      labelColor={labelColor}
      borderColor={borderColor}
      disabled={disabled}
      disabledButtonColor={disabledButtonColor}
      disabledBorderColor={disabledBorderColor}
      disabledTextColor={disabledTextColor}
      onClick={onClick}
      data-name={name}
    >
      <span>{label}</span>
    </ButtonDefault>
  );

  return (
    <ButtonContainer animated={animated || false} className="button-slide-container">
      {!small && defaultButton()}
      {small && smallButton()}
    </ButtonContainer>
  );
};

export default ButtonSlideCustom;
