import { HttpClient } from './client';
import { DXL_WITHOUT_JWT, DXL, DXL_CLOUD, DXL_CLOUD_WITH_JWT } from './config';

export const dxlClientWithOutJwt = new HttpClient(DXL_WITHOUT_JWT);

export const dxlClient = new HttpClient(DXL);

export const dxlCloudClient = new HttpClient(DXL_CLOUD);

export const dxlCloudClientWithJwt = new HttpClient(DXL_CLOUD_WITH_JWT);
