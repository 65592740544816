import { API } from '@vfit/shared/data-access';
import { dxlClientWithOutJwt } from '@vfit/business-data-access';
import { IDXLAnonymousLoginResponse } from './dxlAnonymousLogin.models';

/**
 * @description anonymous login, used to get a token for the first time
 * @returns  {Promise<IDXLAnonymousLoginResponse>}
 */
export const dxlAnonymousLogin = async () =>
  await dxlClientWithOutJwt.get(API.DXL_ANONYMOUS_LOGIN, {
    headers: {
      'Content-Type': 'application/json',
    },
  }) as Promise<IDXLAnonymousLoginResponse>;
