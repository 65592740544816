import { useContext, useEffect, useState } from 'react';
import { GenericDetail, VfModal } from '@vfit/shared/components';
import { useRouter } from 'next/router';
import { LoggerInstance, tracking } from '@vfit/shared/data-access';
import { PAGES } from '@vfit/shared/data-access';
import { ErrorAction, ErrorDetail, ErrorSeverity, ErrorType } from './models';
import { ErrorService } from './errorService';
import { AuthenticationContext } from '../authentication/authentication';

const initialDetail: ErrorDetail = {
  title: 'Ops',
  message: 'Si è verificato un errore.',
  severity: ErrorSeverity.MEDIUM,
  errorAction: ErrorAction.MODAL,
  onClose: () => {},
  actionEvent: () => {},
  secondActionEvent: () => {},
};

export const ErrorWrapper = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalDetail, setModalDetail] = useState<ErrorDetail>(initialDetail);

  const { auth } = useContext(AuthenticationContext);
  const { push } = useRouter();

  const handleError = (error: ErrorDetail) => {
    const errorCases = {
      [ErrorType.AUTHENTICATION]: () => {
        auth();
      },
      fallback: () => {
        LoggerInstance.debug(error);
      },
    };
    errorCases[error?.errorType || 'fallback']();
  };

  const redirectOnErrorCode = (errorCode: number = 0): void => {
    console.log('error Code redirectOnErrorCode', errorCode);
    const offerError = errorCode >= 400 && errorCode < 500;
    const serviceError = errorCode >= 500 && errorCode < 600;
    if (offerError) {
      push(PAGES.OFFER_ERROR);
    } else if (serviceError) {
      push(PAGES.SERVICE_ERROR);
    } else {
      push(PAGES.GENERIC_ERROR);
    }
  };

  const onError = (event: any) => {
    const { detail } = event as CustomEvent as { detail: ErrorDetail };

    const errorAction = {
      [ErrorAction.MODAL]: () => {
        setModalDetail(detail || modalDetail);
        setShowModal(true);
      },
      [ErrorAction.PAGE]: () => {
        //tracking
        tracking(
          {
            page_section: 'eshop',
            page_type: 'error page',
            journey_type: 'task',
            event_label: detail.message || `${detail.errorCode}` || '',
            event_name: ['page_error'],
            event_category: ['error'],
            ...(detail.errorCode ? { page_error: `${detail.errorCode}|_|${detail.message}` } : {}),
          },
          'view'
        );

        if (detail.errorPage) push(detail.errorPage);
        else redirectOnErrorCode(detail.errorCode);
      },
      fallback: () => handleError(detail),
    };
    errorAction[detail.errorAction || 'fallback']();
  };

  const resetModalStatus = () => {
    setModalDetail(initialDetail);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    if (modalDetail.onClose) modalDetail.onClose();
    resetModalStatus();
  };

  const handleActionModal = () => {
    if (modalDetail.actionEvent) modalDetail.actionEvent();
    resetModalStatus();
  };

  const handleSecondActionModal = () => {
    if (modalDetail.secondActionEvent) modalDetail.secondActionEvent();
    resetModalStatus();
  };

  useEffect(() => {
    if (typeof document !== 'undefined') {
      document.addEventListener('errorServiceNotificationEvent', onError);
    }
    return () => document.removeEventListener('errorServiceNotificationEvent', onError);
  }, []);

  return (
    <VfModal isOpen={showModal} handleClose={handleCloseModal}>
      <GenericDetail
        title={modalDetail.title || initialDetail.title || ''}
        description={modalDetail.message || initialDetail.message}
        secondButtonLabel={modalDetail.secondButtonText || ''}
        secondButtonAction={handleSecondActionModal}
        submitButtonLabel={modalDetail.actionText}
        submitButtonAction={handleActionModal}
        options={modalDetail.options}
      />
    </VfModal>
  );
};

export const errorManager = new ErrorService();
