import { breakpoints } from '@vfit/shared/themes';
import styled from 'styled-components';

export const StickyWhatsappComponent = styled.div`
  display: flex;
  position: fixed;
  width: 45px;
  height: 45px;
  background-color: #009900;
  right: 0;
  top: 66vh;
  z-index: 4;
  border-radius: 30px 0px 0px 30px;
  padding: 9px;
  overflow: hidden;
  transition: width 0.5s ease;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    width: 220px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    top: 46.5vh;
  }
`;

export const StickyIcon = styled.div`
  position: fixed;
  width: 28px;
  height: 28px;
`;

export const StickyTitle = styled.span`
  display: flex;
  align-items: center;
  margin-left: 44px;
  color: white;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 700;
`;

