import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import {
  IButtonContainer,
  IButtonDefault,
  IButtonSmall,
  IArrowButton,
} from './buttonSlideCustom.models';

export const ButtonContainer = styled.div<IButtonContainer>`
  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }

    40% {
      transform: translateY(-20px);
    }

    60% {
      transform: translateY(-10px);
    }
  }

  span {
    font-family: ${fonts.regular};
    font-style: normal;
    font-weight: 700;
    ${pxToCssFont(14, 22)}
    text-align: center;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #333;
  }

  animation: ${({ animated }) => (animated ? 'bounce 2s infinite' : '')};
`;

export const ButtonDefault = styled.button<IButtonDefault>`
  background: ${({ buttonColor, disabled, disabledButtonColor }) =>
    !disabled ? buttonColor || colors.$ffffff : disabledButtonColor || '#ccc'};
  border-radius: 32px;
  padding: 10.5px 15px;
  margin: 0;
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
  border: 1px solid ${({ borderColor }) => borderColor || colors.$ffffff};
  width: 100%;
  -webkit-tap-highlight-color: transparent;

  :hover {
    @media (min-width: ${breakpoints.desktop}) {
      transition: background-color 400ms linear;
      background: ${({ hoverColor, buttonColor, disabled }) =>
        disabled ? '#ccc' : hoverColor || buttonColor || colors.$ffffff};
      border: 1px solid
        ${({ hoverColor, borderColor }) =>
          hoverColor ? 'transparent' : borderColor || colors.$ffffff};

      span {
        color: ${({ hoverTextColor, labelColor }) =>
          hoverTextColor || labelColor || colors.$262626};
      }
    }
  }

  span {
    color: ${({ labelColor }) => labelColor || colors.$262626};
  }

  :active {
    background: ${({ clickColor, buttonColor }) => clickColor || buttonColor || colors.$ffffff};
    border: 1px solid
      ${({ clickColor, borderColor }) =>
        clickColor ? 'transparent' : borderColor || colors.$ffffff};

    span {
      color: ${({ clickTextColor, labelColor }) => clickTextColor || labelColor || colors.$262626};
    }
  }

  :disabled {
    border: 1px solid ${({ disabledBorderColor }) => disabledBorderColor || colors.$bebebe};
    background-color: ${({ disabledButtonColor }) => disabledButtonColor || colors.$ffffff};

    span {
      color: ${({ disabledTextColor }) => disabledTextColor || colors.$bebebe};
    }

    cursor: not-allowed;
  }

  @media (hover: none) and (pointer: coarse) {
    :hover {
      background: ${({ buttonColor, disabled }) =>
        disabled ? '#ccc' : buttonColor || colors.$ffffff};
      border: 1px solid ${({ borderColor }) => borderColor || colors.$ffffff};

      span {
        color: ${({ labelColor }) => labelColor || colors.$262626};
      }
    }

    :active {
      background: ${({ clickColor, buttonColor }) => clickColor || buttonColor || colors.$ffffff};
      border: 1px solid
        ${({ clickColor, borderColor }) => clickColor || borderColor || colors.$ffffff};

      span {
        color: ${({ clickTextColor, labelColor }) =>
          clickTextColor || labelColor || colors.$262626};
      }
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding: 10px 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 10px 24px;
  }

  &.ButtonDefault-style-1 {
    background: ${({ disabled }) => !disabled ? colors.$e60000 : '#ccc'};
    border: ${({ disabled }) => !disabled ? `1px solid ${colors.$e60000}` : `1px solid #ccc`};
    
    :hover {
      @media (min-width: ${breakpoints.desktop}) {
        background: ${({ disabled }) => (disabled ? '#ccc' : colors.$bd0000)};
        border: 1px solid transparent;

        span {
          color: ${colors.$ffffff};
        }
      }
    }

    span {
      color: ${colors.$ffffff};
    }

    :active {
      background: ${colors.$a10000};
      border: 1px solid transparent;

      span {
        color: ${colors.$ffffff};
      }
    }

    @media (hover: none) and (pointer: coarse) {
      :hover {
        background: ${({ disabled }) => (disabled ? '#ccc' : colors.$e60000)};
        border: 1px solid ${colors.$ffffff};
      }

      :active {
        background: ${colors.$a10000};
        border: 1px solid ${colors.$a10000};
      }
    }
  }

  // this style needs to be changed with background trasparent or it's necessary to use the new button component that manage it correcty!
  &.ButtonDefault-style-2 {
    background: ${({ disabled }) => (!disabled ? colors.$ffffff : '#ccc')};
    border: 1px solid ${colors.$262626};

    :hover {
      @media (min-width: ${breakpoints.desktop}) {
        background: ${({ disabled }) => (disabled ? '#ccc' : colors.$262626)};
        border: 1px solid transparent;

        span {
          color: ${colors.$ffffff};
        }
      }
    }

    span {
      color: ${colors.$262626};
    }

    :active {
      background: #7e7e7e;
      border: 1px solid transparent;

      span {
        color: ${colors.$ffffff};
      }
    }

    @media (hover: none) and (pointer: coarse) {
      :hover {
        background: ${({ disabled }) => (disabled ? '#ccc' : colors.$ffffff)};
        border: 1px solid ${colors.$262626};
        span {
          color: ${colors.$262626};
        }
      }

      :active {
        background: #7e7e7e;
        border: 1px solid #7e7e7e;
      }
    }
  }

  // added style 3 with white background and no border to handle components that don't yet use the new button
  &.ButtonDefault-style-3 {
    background: ${({ disabled }) => (!disabled ? colors.$ffffff : colors.$ffffff_60)};
    border: none;

    span {
      color: ${({ disabled }) => (!disabled ? colors.$262626 : colors.$bebebe)};
    }

    :hover {
      background: ${colors.$ffffff_60}
    }

    :active {
      background: ${colors.$ffffff_80};
    }

    @media (hover: none) and (pointer: coarse) {
      :hover {
        background: ${colors.$ffffff_60}
      }
    }
  }
`;

export const ButtonSmall = styled.button<IButtonSmall>`
  background: ${colors.$ffffff};
  width: ${({ size }) => (size ? `${size}px` : '60px')};
  height: ${({ size }) => (size ? `${size}px` : '60px')};
  border-radius: 44px;
  border: none;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  background-color: ${({ buttonColor }) => buttonColor || colors.$ffffff};

  :hover {
    background: ${({ hoverColor, buttonColor }) => hoverColor || buttonColor || colors.$ffffff};
  }

  :active {
    background: ${({ clickColor, buttonColor }) => clickColor || buttonColor || colors.$ffffff};
  }

  &.ButtonDefault-style-1 {
    background-color: ${colors.$e60000};

    :hover {
      background: ${colors.$bd0000};
    }

    :active {
      background: ${colors.$a10000};
    }
  }

  &.ButtonDefault-style-2,
  &.ButtonDefault-style-3 {
    background-color: ${colors.$ffffff};

    :hover {
      background: ${colors.$262626};
    }

    :active {
      background: '#7E7E7E';
    }
  }
`;

export const ArrowButton = styled.div<IArrowButton>`
  transform: rotate(${({ rotation }) => `${rotation}deg`});
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: ${({ arrowWidth }) => (arrowWidth ? `${arrowWidth}px` : '9px')};
    height: ${({ arrowHeigth }) => (arrowHeigth ? `${arrowHeigth}px` : '16px')};

    path {
      stroke: ${({ color }) => color};
    }
  }
`;

