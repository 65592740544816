import { dxlClientWithOutJwt } from '@vfit/business-data-access';
import { API } from '@vfit/shared/data-access';
import { IDXLCookieLoginResponse } from "./dxlCookieLogin.models";

/**
 * @description cookie login, use ObSSOCookie to get a JWT token
 * @returns  {Promise<IDXLCookieLoginResponse>}
 */
export const dxlCookieLogin = async () =>
  await dxlClientWithOutJwt.get(
    API.DXL_COOKIE_LOGIN, { credentials: 'include' }
  ) as Promise<IDXLCookieLoginResponse>;
