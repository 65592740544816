/**
 * removes the storage indicated by the passed key from the session storage
 * @param key denotes storage key 
 * 
 */
 export const removeToken = (key: string) => {
    if (typeof window !== 'undefined') {
      return window.sessionStorage.removeItem(key);
    }
    return false;
  };
  