import type { Options, KyInstance } from 'ky';
import ky from 'ky';
import { LoggerInstance } from '@vfit/shared/data-access';

export class HttpClient {
  private _client: KyInstance;

  constructor(defaultOptions: Options) {
    this._client = ky.create(defaultOptions);
  }

  get = async (endpoint: string, options?: Options | any) => {
    try {
      LoggerInstance.debug('API chiamata '.concat(endpoint));
      return await this._client
        .get(endpoint, {
          ...options,
        })
        .json();
    } catch (error) {
      LoggerInstance.error('error', error);
      throw error;
    }
  };

  post = async (endpoint: string, payload?: BodyInit | any, options?: Options)=> {
    try {
      return await this._client
        .post(endpoint, {
          ...options,
          ...(payload && { json: payload }),
        })
        .json();
    } catch (error) {
      LoggerInstance.error('error', error);
      throw error;
    }
  };

  put = async (endpoint: string, payload?: BodyInit | any, options?: Options) => {
    try {
      return await this._client
        .put(endpoint, {
          ...options,
          ...(payload && { json: payload }),
        })
        .json();
    } catch (error) {
      LoggerInstance.error('error', error);
      throw error;
    }
  };

  delete = async (endpoint: string, payload?: BodyInit | any, options?: Options) => {
    try {
      return await this._client
        .delete(endpoint, {
          ...options,
          ...(payload && { json: payload }),
        })
        .json();
    } catch (error) {
      LoggerInstance.error('error', error);
      throw error;
    }
  };

  head = async (endpoint: string, options?: Options) => {
    try {
      return await this._client.head(endpoint, { ...options }).json();
    } catch (error) {
      throw new Error(`error, /todoImplement error manager: ${error}`);
    }
  };

  patch = async (endpoint: string, payload?: BodyInit | any, options?: Options) => {
    try {
      return await this._client
        .patch(endpoint, {
          ...options,
          ...(payload && { json: payload }),
        })
        .json();
    } catch (error) {
      LoggerInstance.error('error', error);
      throw error;
    }
  };
}

