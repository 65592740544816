import { LoggerInstance } from "@vfit/shared/data-access";
import { ErrorDetail, ErrorSeverity } from "./models";

export class ErrorService {
  private logger = LoggerInstance;

  private customErrorEvent: CustomEvent | undefined;

  /**
   * Handle Errors
   */
  public handleError (
    severity: ErrorSeverity,
    errorDetail: ErrorDetail
  ) : void {
    switch (severity) {
      case ErrorSeverity.HIGH:
        this.handleHighSeverity(errorDetail)
        break;
      case ErrorSeverity.MEDIUM:
        this.handleMediumSeverity(errorDetail)
        break;
      case ErrorSeverity.LOW:
      default:
        this.handleLowSeverity(errorDetail.message, errorDetail.errorCode)
    }
  }

  /**
   * Handle high severity error
   */
  private handleHighSeverity(errorDetail: ErrorDetail): void {
    const detail: ErrorDetail = {
      ...errorDetail,
      severity : ErrorSeverity.HIGH,
    }

    this.customErrorEvent = new CustomEvent('errorServiceNotificationEvent', { detail });
    document.dispatchEvent(this.customErrorEvent);
  }

  /**
   * Handle medium severity error
   */
  public handleMediumSeverity(errorDetail: ErrorDetail): void {
    const detail: ErrorDetail = {
      ...errorDetail,
      severity : ErrorSeverity.MEDIUM
    }

    this.customErrorEvent = new CustomEvent('errorServiceNotificationEvent', { detail });
    document.dispatchEvent(this.customErrorEvent);
  }

  /**
   * Handle low severity error
   */
  public handleLowSeverity(description?: string, errorCode?: number ): void {
    this.logger.warn(`SEVERITY: ${ErrorSeverity.LOW} | ERROR CODE: ${errorCode} | ${description}`);
  }
}



