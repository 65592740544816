import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { Hydrate } from 'react-query';
import { PopupWindHPSunrise, ScrollRestoration, YextSearch } from '@vfit/consumer/components';
import { ErrorBoundary } from '@vfit/business-components';
import {
  FullRenderProvider,
  TrackingContextProvider,
  QueryProvider,
} from '@vfit/shared/data-access';
import { ReactQueryDevtools } from 'react-query/devtools';
import '../styles/css/globals.css';
import '../styles/carousel/offerCarousel.scss';
import '../styles/popupWindSunrise/popupWindSunrise.scss';

import 'swiper/css';
import 'swiper/css/pagination';
import {
  AppDynamics,
  DatadogWrapper,
  HeadSeo,
  ModalErrorWrapper,
  ModalIframeWrapper,
  ModalLeadWrapper,
  StickyWhatsapp,
} from '@vfit/shared/components';
import { AuthenticationProvider, ErrorWrapper } from '@vfit/business-data-access';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactNode) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const RELEASE_VERSION = process?.env?.['NEXT_PUBLIC_TAG_RELEASE_VERSION'] ?? process?.env?.['NX_TAG_RELEASE_VERSION'] ?? 'not-available';

const DefaultApp = ({ Component, pageProps }: AppPropsWithLayout) => {
  const [loading, setLoading] = useState<boolean>(
    !(pageProps?.page?.params?.elements?.seo?.fullSEO === 'true')
  );
  const [loadingClient, setLoadingClient] = useState<boolean>(true);

  const getLayout = Component.getLayout ?? ((page) => page);
  const { elements } = pageProps?.page?.params || {};
  const { seo, supportwhatsapp } = elements || {};
  const showStickyWhatsapp = supportwhatsapp?.['isVisible']?.toLowerCase() === 'true';

  useEffect(() => {
    setLoading(false);
    setLoadingClient(false);
  }, []);

  const loadingComponent = () => <div />;

  const ClientComponents = () => {
    if (!loadingClient) {
      return (
        <>
          <YextSearch />
          <AppDynamics />
          <ScrollRestoration />
          <ErrorWrapper />
          <ModalErrorWrapper />
          <ModalLeadWrapper />
          <ModalIframeWrapper />
          <PopupWindHPSunrise />
          <ReactQueryDevtools initialIsOpen={false} />
        </>
      );
    }
  };
  return (
    <>
      <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" crossOrigin="use-credentials" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta httpEquiv="refresh" content="18000" />
        <meta name="release-version" content={`${RELEASE_VERSION}`} />
      </Head>
      <HeadSeo {...seo} />
      <AppDynamics isConfig />
      <ErrorBoundary>
        <DatadogWrapper />
        <QueryProvider>
          <FullRenderProvider fullRender={!!seo?.fullSEO}>
            <Hydrate state={pageProps.dehydratedState}>
              <TrackingContextProvider project="business">
                <AuthenticationProvider authDisabled={!!pageProps?.page?.params?.authDisabled}>
                  {showStickyWhatsapp && <StickyWhatsapp {...supportwhatsapp} />}
                  {loading && loadingComponent()}
                  {!loading && getLayout(<Component {...pageProps} />)}
                  <ClientComponents />
                </AuthenticationProvider>
              </TrackingContextProvider>
            </Hydrate>
          </FullRenderProvider>
        </QueryProvider>
      </ErrorBoundary>
    </>
  );
};

export default DefaultApp;
