import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { dxlAnonymousLogin, dxlCookieLogin, isLogged } from '@vfit/business-data-access';
import { removeToken, setToken } from '@vfit/shared/data-access';
import {
  handleUseQuery,
  handleUseQueryes,
  LoggerInstance,
  UseQueryConfig,
} from '@vfit/shared/data-access';
import { useQuery, useQueryClient } from 'react-query';

export enum EAuthType {
  CB = 'CB',
  PROSPECT = 'Prospect',
  NONE = 'None',
}

interface IAuthenticationContext {
  auth: () => Promise<void>;
  authType: EAuthType;
}

export const AuthenticationContext = createContext<IAuthenticationContext>({
  auth: async () => {},
  authType: EAuthType.NONE,
});

export const AuthenticationProvider = ({
  children,
  authDisabled,
}: {
  children: ReactNode;
  authDisabled: boolean;
}) => {
  const [authType, setAuthType] = useState<EAuthType>(EAuthType.NONE);

  const client = useQueryClient();

  const logout = async () => {
    setAuthType(EAuthType.NONE);
    removeToken('dxlAuthTokenCB');
    removeToken('dxlAuthToken');
    await client.invalidateQueries({ queryKey: 'CBProfile', exact: false });
  };

  const cookieLogin = async () => {
    console.log('auth flow: cookieLogin');
    await client.invalidateQueries({ queryKey: 'CBProfile', exact: false });
    const { auth_token } = await dxlCookieLogin();
    setToken('dxlAuthTokenCB', auth_token);
    setAuthType(EAuthType.CB);
  };

  const anonymousLogin = async () => {
    console.log('auth flow: anonymousLogin');
    await logout();
    const { auth_token } = await dxlAnonymousLogin();
    setToken('dxlAuthToken', auth_token);
    setAuthType(EAuthType.PROSPECT);
  };

  const auth = async () => {
    try {
      const logged = await isLogged();
      if (logged) {
        await cookieLogin();
        return;
      }
    } catch (_) {
      console.log('Error');
    }
    await anonymousLogin().catch((_) => {
      setAuthType(EAuthType.PROSPECT);
      setToken('dxlAuthToken', 'temporary');
      // if (!window.location.pathname.includes(PAGES.GENERIC_ERROR))
      //   errorManager.handleError(ErrorSeverity.HIGH, {errorAction: ErrorAction.PAGE, errorPage: PAGES.GENERIC_ERROR});
    });
  };

  useQuery('isLogged', () => !authDisabled && isLogged(), {
    refetchInterval: 130000,
    enabled: authType === EAuthType.CB,
    onSuccess: (res: boolean) => {
      if (!res) auth();
    },
    onError: (err) => {
      LoggerInstance.error(err);
    },
  });

  useEffect(() => {
    console.log('auth flow: useEff --> auth()');
    !authDisabled && auth();
  }, []);

  return (
    <AuthenticationContext.Provider value={{ auth, authType }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuth = (): { isAuthenticated: boolean; auth: () => Promise<void> } => {
  const { auth, authType } = useContext(AuthenticationContext);

  return { isAuthenticated: authType != EAuthType.NONE, auth };
};

export const useHandleQueryWithAuthSSO = (config: UseQueryConfig) => {
  const { isAuthenticated } = useAuth();

  return handleUseQuery({
    ...config,
    options: {
      ...config.options,
      enabled:
        config.options?.enabled != undefined
          ? config.options?.enabled && isAuthenticated
          : isAuthenticated,
    },
  });
};

export const useHandleQueriesWithAuthSSO = (queries: UseQueryConfig[]) => {
  const { isAuthenticated } = useAuth();

  console.log('Authenticated: ', isAuthenticated); // TRUE

  return handleUseQueryes(
    queries.map((query) => ({
      ...query,
      options: {
        ...query.options,
        enabled:
          query.options?.enabled != undefined
            ? query.options?.enabled && isAuthenticated
            : isAuthenticated,
      },
    }))
  );
};

