import { IOptions } from '@vfit/shared/models';

export interface ErrorDetail {
  title?: string;
  message?: string;
  severity?: ErrorSeverity;
  errorType?: ErrorType;
  errorAction?: ErrorAction;
  errorPage?: string;
  errorCode?: number;
  actionEvent?: () => void;
  secondActionEvent?: () => void;
  onClose?: () => void;
  actionText?: string;
  secondButtonText?: string;
  options?: IOptions;
}

export enum ErrorSeverity {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

// TODO: map errorType.
export enum ErrorType {
  AUTHENTICATION = 'authentication',
}

export enum ErrorAction {
  MODAL = 'modal',
  PAGE = 'page'
}

/*

errorManager.handleError(ErrorService.getSeverityErrorHigh(), {
  title: config.title || '',
  message: config.message || '',
  actionText: config.action.title || '',
  ...(config?.action?.type && {
    actionEvent: () => getButtonActionByActionType(config.action, push),
  }),
});

 */
