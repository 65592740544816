import { API } from '@vfit/shared/data-access';
import ky, { Options } from "ky";
import { xml2js } from "xml-js";
import { handleEnv } from "@vfit/shared/data-access";
import { IIsLoggedResponse } from "./isLogged.models";

/**
 * @description isLogged, use ObSSOCookie to get a JWT token
 * @returns  {Promise<IDXLCookieLoginResponse>}
 */
export const isLogged = async (): Promise<boolean> => {
  const options = {
    compact: true,
    nativeType: true,
    ignoreDeclaration: true
  }
  const res = await ky.get(
    API.IS_LOGGED,
    {
      prefixUrl: 'https://login.vodafone.it/',
      searchParams: {
        k: handleEnv('NEXT_PUBLIC_KEYSITE'),
        format: 'xml'
      },
      credentials: 'include'
    } as Options
  ).text();

  return (xml2js(res, options) as IIsLoggedResponse).islogged.logged._text == 1;
}
