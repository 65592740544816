import { breakpoints, pxToCssFont } from '@vfit/shared/themes';
import styled from 'styled-components';

export const StickyWhatsappModalContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  button {
    margin-bottom: 24px;
  }
`;

export const ContentLeft = styled.div`
  width: 100%;

  @media (min-width: ${breakpoints.desktop}) {
    width: 350px;
  }
`;

export const ContentRight = styled.div`
  width: 250px;
`;

export const ModalTitle = styled.div`
  ${pxToCssFont(18, 16)}
  div {
    margin: 0;
    line-height: 42px;
    margin-bottom: 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const ModalDescription = styled.div`
  div {
    margin: 0;
    line-height: 24px;
  }
`;

export const ImageContainer = styled.div`
  max-width: 180px;
  margin: 24px auto;
`;

export const ModalSupport = styled.div`
  display: flex;

  @media (min-width: ${breakpoints.desktop}) {
    span {
      font-size: 24px;
    }
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 32px !important;
    height: 32px !important;
    max-width: 32px;
  }
`;

