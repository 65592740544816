import { HTTPError, Options } from 'ky';
import {DxlError, LoggerInstance, NextError, checkJWTsValidity, checkWindow, delay, handleEnv} from "@vfit/shared/data-access";
const defaultConfig: Options = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json, text/plain, */*',
    clientId: '9003',
    keysite: handleEnv('NEXT_PUBLIC_KEYSITE') || '',
  },
  cache: 'no-cache',
  hooks: {
    afterResponse: [
      async (_request, _options, response) => {
        if (handleEnv('NODE_ENV') === 'LOCAL' && checkWindow()) await delay(1000);
        if (!response.ok) {
          throw new NextError(response, _request).getError();
          // throw new DxlError(
          //   `${response.status}|_|${
          //     response.statusText || 'UNKNOWN_ERROR_RESPONSE_NOT_OK'
          //   }|_|Ops, si è verificato un errore.`
          // );
        }
      },
    ],
  },
  prefixUrl: handleEnv('NEXT_PUBLIC_DXL_VBI_PREFIX') || '',
  retry: {
    limit: 3,
    methods: ['get'],
  },
  timeout: 30000,
};

export const DXL_WITHOUT_JWT: Options = {
  ...defaultConfig,
  headers: { ...defaultConfig.headers, transactionId: Date.now().toString() },
};

export const DXL: Options = {
  ...defaultConfig,
  hooks: {
    ...defaultConfig.hooks,
    beforeRequest: [
      ...(defaultConfig.hooks?.beforeRequest || []),
      async (request) => {
        if (!checkWindow()) return;

        const token = checkJWTsValidity(['dxlAuthTokenCB', 'dxlAuthToken']);
        if (token) request.headers.set('X-Auth-Token', token);
        else {
          LoggerInstance.error('Invalid JWT');
          throw new DxlError(`500|_|'AUTH_EXPIRED'|_|'La sessione è scaduta, ricarica la pagina'`);
        }
      },
    ],
  },
};

export const DXL_CLOUD: Options = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json, text/plain, */*',
  },
  cache: 'no-cache',
  prefixUrl: handleEnv('NEXT_PUBLIC_DXL_VBI_CLOUD_PREFIX') || '',
  hooks: {
    afterResponse: [
      async (_request, _options, response) => {
        if (!response.ok) {
          throw new HTTPError(response, _request, _options);
        }
      },
    ],
  },
  retry: 0,
  timeout: 30000,
};

export const DXL_CLOUD_WITH_JWT: Options = {
  ...DXL_CLOUD,
  hooks: {
    ...DXL_CLOUD.hooks,
    beforeRequest: [
      ...(defaultConfig.hooks?.beforeRequest || []),
      async (request) => {
        if (!checkWindow()) return;

        const token = checkJWTsValidity(['dxlAuthTokenCB', 'dxlAuthToken']);
        if (token) request.headers.set('X-Auth-Token', token);
        else {
          LoggerInstance.error('Invalid JWT');
          throw new DxlError(`500|_|'AUTH_EXPIRED'|_|'La sessione è scaduta, ricarica la pagina'`);
        }
      },
    ],
  },
};

