/**
 * set the value of a specific storage
 * @param item is storage key
 */
export const setToken = (key: string, value: string) => {
    if (typeof window !== 'undefined') {
      return window.sessionStorage.setItem(key,value);
    }
    return false;
  };
  