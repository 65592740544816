/* eslint-disable */
import { useEffect } from 'react';

const PopupWindHPSunrise = () => {
  const waitForElement = async (selector: string) => {
    return new Promise((resolve) => {
      if (document.querySelector(selector)) {
        return resolve(document.querySelector(selector));
      }

      const observer = new MutationObserver((mutations) => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });
    });
  };

  useEffect(() => {
    waitForElement('#vf_ps_popPorting').then((elm) => {
      document.querySelectorAll('a.vf_ps_closePopWind').forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();

          (document.getElementById('vf_ps_popPorting') as HTMLElement).style.display = 'none';
          (document.getElementById('vf_ps_popPortingCNT') as HTMLElement).style.display = 'block';
          (document.getElementById('vf_ps_popLead') as HTMLElement).style.display = 'none';
        });
      });
      document.querySelectorAll('a.vf_ps_popLeadWind').forEach((link) => {
        link.addEventListener('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          let $lnk = link.getAttribute('href');
          (document.getElementById('vf_ps_popPortingCNT') as HTMLElement).style.display = 'none';
          (document.getElementById('vf_ps_popLead') as HTMLElement).style.display = 'block';
          (document.getElementById('iframeLead') as HTMLElement).innerHTML =
            '<iframe src="' + $lnk + '" scrolling="no"></iframe>';
        });
      });
    });
  }, []);
  return (
    <section id="vf_ps_popPorting" data-desc="Fissa Smart">
      <div
        id="vf_ps_popPortingCNT"
        className="vf_ps_popPorting vf_ps_roundedBorder vf_ps_shadowedBox clearfix"
      >
        <a href="javascript:void(0);" className="vf_ps_closePopWind" title="close popup"></a>
        <article>
          <header>
            <h2>
              <strong>Passa a Vodafone e risparmia</strong>
            </h2>
            <p>
              <strong>Solo online</strong> hai la possibilità di attivare ad un prezzo vantaggioso
              Internet per la tua P.IVA alla massima velocità con <strong>internet</strong>,{' '}
              <strong>chiamate</strong> e <strong>modem inclusi nel prezzo</strong>.
            </p>
          </header>

          <aside className="vf_ps_roundedBorder clearfix">
            <p>
              <strong>Fissa Smart</strong>
              <del>A soli 30&euro;</del> <strong>23&euro;</strong> IVA escl.
              <b>Primi due mesi gratis</b>
            </p>
            <ul className="clearfix">
              <li>
                <img
                  src="https://a-wb.vodafone.it/content/dam/webaem/img/campagne/wind-2023/ico-blk-internet.png"
                  alt=""
                />{' '}
                <strong>Internet senza limiti</strong>
              </li>
              <li>
                <img
                  src="https://a-wb.vodafone.it/content/dam/webaem/img/campagne/wind-2023/ico-blk-chiamate.png"
                  alt=""
                />{' '}
                <strong>Chiamate illimitate</strong>
              </li>
            </ul>
          </aside>

          <footer>
            <a
              href="https://assistenza.vodafone.it/cmb/adsl_business/call_me_now.php?DESC=HP_fissa-smart_popup_gnp&landing_page=business.vodafone.it"
              className="vf_ps_pls vf_rzf_pls-green vf_ps_popLeadWind"
            >
              <strong>Ti chiamiamo noi</strong>
            </a>
          </footer>
        </article>
      </div>

      <div id="vf_ps_popLead" className="vf_ps_popLead vf_ps_shadowedBox clearfix">
        <a href="javascript:void(0);" className="vf_ps_closePopWind" title="close popup"></a>
        <div id="iframeLead"></div>
      </div>
    </section>
  );
};
export default PopupWindHPSunrise;
